/* eslint-disable no-template-curly-in-string */
import { ConfigProvider } from 'antd';
import { FC, lazy, Suspense } from 'react';
import { useTheme } from 'theme-context';
import { getUser } from './app/services/selector-helpers';
import { useAppSelector } from './app/store';
import DashboardWrapper from './components/templates/dashboard-wrapper';
import GuestWrapper from './components/templates/guest-wrapper';
import { protectedRouts } from './constants/routes';
import { IRoute } from './interfaces/global-interfaces';

interface ContainerProps {
  route: any;
}

const lazyLoadPage = (path: string) => lazy(async () => await import(`./${path}`));

const PageContainer: FC<ContainerProps> = ({ route }) => {
  const { colors } = useTheme();
  const user = useAppSelector(getUser);
  const Children = lazyLoadPage(route.component);
  let content = (
    <GuestWrapper>
      <Children />
    </GuestWrapper>
  );
  if (user?.userId) {
    content = (
      <DashboardWrapper>
        <Children />
      </DashboardWrapper>
    );
  }

  return (
    <Suspense>
      <ConfigProvider
        getPopupContainer={node => {
          if (node) {
            return node.parentNode as HTMLDivElement;
          }
          return document.body;
        }}
        form={{
          validateMessages: {
            // required: '${label} is required!',
            required: 'Field is required!',
          },
        }}
        theme={{
          token: {
            borderRadius: 1,
            colorError: '#FF3E01',
            colorPrimary: colors.main_color,
            colorLink: colors.main_color,
            colorInfo: colors.main_color,
            colorFillContentHover: colors.main_color,
            colorSuccess: '#00D78F',
            fontFamily: 'Roboto',
            fontSize: 16,
            sizePopupArrow: 14,
            lineWidth: 0.2,
            colorBorderBg: colors.main_color,
            controlHeight: 35,
            colorTextDisabled: '#000000e0',
          },
          components: {
            Form: {
              labelHeight: 12,
              verticalLabelPadding: '0 0 2px 0',
              lineHeight: 1,
              itemMarginBottom: 15,
              labelColonMarginInlineEnd: 0,
              labelColonMarginInlineStart: 0,
              labelColor: colors.main_color,
              // labelFontSize: 12,
              labelRequiredMarkColor: '#FF3E01',
              colorBorder: 'transparent',
              colorError: '#FF3E01',
              colorPrimary: colors.main_color,
              controlOutline: 'transparent',
              fontSize: 12,
            },
            Select: {
              colorBorder: colors.main_color,
              colorPrimary: colors.main_color,
              multipleItemHeight: 29,
              // singleItemHeightLG: 29,
            },
            DatePicker: {
              colorBorder: colors.main_color,
              colorPrimary: colors.main_color,
            },
            Button: {
              contentFontSize: 16,
              defaultBg: colors.main_color,
              defaultBorderColor: colors.main_color,
              colorTextDisabled: '#7c7c7c',
              fontWeight: 700,
              borderColorDisabled: '#7c7c7c',
            },
            Collapse: {
              headerPadding: '8px 15px',
              contentPadding: 0,
              lineWidth: 0,
              colorTextHeading: 'white',
              borderRadiusLG: 0,
              fontSize: 16,
              marginSM: 10,
              padding: 0,
            },
          },
        }}
      >
        {content}
      </ConfigProvider>
    </Suspense>
  );
};

export default PageContainer;

export const getPageComponents = (role: string) => {
  const components = {};
  Object.values(protectedRouts[role]).forEach((route: IRoute) => {
    components[route.key] = {
      component: route.component,
      pathname: route.pathname,
      redirectPath: route.redirectPath,
      pageTitle: route.pageTitle,
      key: route.key,
    };

    if (route.items) {
      Object.values(route.items).forEach((childRoute: IRoute) => {
        components[childRoute.key] = {
          component: childRoute.component,
          pathname: childRoute.pathname,
          redirectPath: route.redirectPath,
          pageTitle: route.pageTitle,
          key: route.key,
          subKey: route.subKey,
        };
      });
    }
  });

  return components;
};
