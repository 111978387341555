import { toggleTheme } from 'app/slices/theme';
import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { darkThemeColors, lightThemeColors } from './constants/colorsProxy';

// Define ThemeContextType
interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
  colors: any;
}

// Create Theme Context
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isDark = useSelector((state: any) => state.theme.isDark); // Redux theme state
  const dispatch = useDispatch();

  const handleToggleTheme = () => dispatch(toggleTheme());

  // Provide the current theme colors
  const colors = isDark ? darkThemeColors : lightThemeColors;

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme: handleToggleTheme, colors }}>
      <StyledThemeProvider theme={colors}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

// Custom Hook for Theme Context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
