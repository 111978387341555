import { Button as button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

interface IMainButtonProps extends Omit<ButtonProps, 'color'> {
  width?: string;
  radius?: string;
  fw?: number;
  fz?: string;
  height?: string;
  margin?: string;
  color?: string;
  icon_height?: string;
  custom_type?: string;
  max_width?: string;
  padding?: string;
  mb?: number;
  mt?: number;
  text_wrap?: string;
  overflow?: string;
  display?: string;
  border?: string;
  opacity?: number;
  align?: string;
  shadow?: string;
  justify?: string;
  line_height?: string;
  hover_border?: string;
  back_color?: string;
}

const Button = styled(button)<IMainButtonProps>`
  ${props =>
    props.custom_type === 'outlined' &&
    css`
      background-color: #fff;
      border: ${props => `1px solid ${props.theme.back_color}`};

      &:disabled {
        background-color: #a5a5a5;
      }
    `}
  ${props =>
    props.custom_type === 'footer' &&
    css`
      background-color: #fff;
      border: ${props => `1px solid ${props.theme.main_color}`};
      color: ${props => props.theme.main_color};
      border-radius: 2px;
      padding: 2.5px 15px;
      height: fit-content;
      display: flex;
      align-items: center;
      box-shadow: 2px 2px 4px 0px #00000040;

      &:hover {
        border: ${props => `1px solid ${props.theme.main_color}`};
        background-color: ${props => props.theme.main_color} !important;
        color: #fff !important;
      }
      &:disabled {
        background-color: #a5a5a5;
      }
    `}
    ${props =>
    props.custom_type === 'footer_add' &&
    css`
      background: #fff;
      border: ${props => `1px solid ${props.theme.main_color}`};
      border-radius: 2px;
      padding: 2.5px 15px;
      height: fit-content;
      display: flex;
      align-items: center;
      box-shadow: 2px 2px 4px 0px #00000040;

      &:hover {
        border: ${props => `1px solid ${props.theme.main_color}`};
        background-color: blue !important;
      }
      &:disabled {
        background-color: #a5a5a5;
      }
      &:enabled {
        color: #002885;
      }
    `}
    ${props =>
    props.custom_type === 'footer_save' &&
    css`
      background: transparent;
      border: ${props => `1px solid ${props.theme.main_color}`};
      border-radius: 2px;
      padding: 2.5px 15px;
      height: fit-content;
      display: flex;
      align-items: center;
      box-shadow: 2px 2px 4px 0px #00000040;

      &:hover {
        border: ${props => `1px solid ${props.theme.main_color}`};
        background-color: transparent !important;
      }
      &:disabled {
        background-color: #a5a5a5;
      }
      &:enabled {
        color: #fff;
        background-color: transparent !important;
        border: 1px solid #fff;
      }
    `}
    ${props =>
    props.custom_type === 'footer_submit' &&
    css`
      /* background-color: #002885 !important; */
      background: #002885 !important;
      /* border: ${props => `1px solid ${props.theme.main_color}`}; */
      border-radius: 2px;
      padding: 2.5px 15px;
      height: fit-content;
      display: flex;
      align-items: center;
      box-shadow: 2px 2px 4px 0px #00000040;

      &:hover {
        border: ${props => `1px solid ${props.theme.main_color}`};
        background-color: #002885 !important;
      }
      &:disabled {
        background-color: #a5a5a5;
      }
      &:enabled {
        color: #fff;
      }
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
    ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw};
    `}
  ${props =>
    props.fz &&
    css`
      font-size: ${props.fz}!important;
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height}!important;
    `}

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
      & span {
        color: ${props.color};
      }
    `}
  ${props =>
    props.icon_height &&
    css`
      svg {
        height: ${props.icon_height};
      }
    `}
   ${props =>
    props.text_wrap &&
    css`
      text-wrap: ${props.text_wrap};
    `}
   ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
    ${props =>
    props.max_width &&
    css`
      max-width: ${props.max_width};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding} !important;
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    (props.mt ?? props.mt === 0) &&
    css`
      margin-top: ${props.mt}px;
    `}
  ${props =>
    props.display &&
    css`
      display: ${props.display};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.back_color &&
    css`
      background: ${props.back_color} !important;
      box-shadow: none;
      &:hover {
        background: ${props.back_color}90 !important;
      }
      &:active {
        background: ${props.back_color} !important;
      }
      &:focus {
        background: ${props.back_color} !important;
      }
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border} !important;
    `}
    ${props =>
    props.opacity &&
    css`
      opacity: ${props.opacity} !important;
    `}
  ${props =>
    props.align &&
    css`
      display: flex;
      align-items: ${props.align};
    `}
  ${props =>
    props.justify &&
    css`
      display: flex;
      justify-content: ${props.justify};
    `}
  ${props =>
    props.line_height &&
    css`
      line-height: ${props.line_height};
    `}
  ${props =>
    props.hover_border &&
    css`
      &:hover {
        border-color: ${props.hover_border};
      }
    `}
`;

export default Button;
