import type { DatePickerProps } from 'antd';
import { DatePicker as AntdDatePicker } from 'antd';
import styled, { css } from 'styled-components';

interface CustomDatePickerProps {
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
  isDark?: boolean;
  color?: string;
}

type IMainDatePickerProps = CustomDatePickerProps & DatePickerProps;

const DatePicker = styled(AntdDatePicker)<IMainDatePickerProps>`
  &.ant-picker {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
    input {
      color: ${props => (props.isDark ? '#fff' : '#000')};
    }
  }

  box-shadow: ${props =>
    props.isDark ? '0px 4px 4px 0px #FFFFFF40 inset' : '0 4px 8px 0 #060c2933 inset'};
  border-radius: 2px;
  font-size: 14px;
  background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
  border: 0.2px solid #060c2940;
  &:hover {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
  }
  & input {
    color: ${props => (props.isDark ? '#fff' : '#000')};
  }

  & .ant-picker-clear {
    color: ${props => (props.isDark ? '#00d78f' : '#000')};
  }

  & .ant-picker-suffix {
    color: ${props => props.theme.main_color};
  }
  &.ant-picker-disabled {
    background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')};
    input {
      color: ${props => (props.isDark ? '#fff' : '#000')} !important;
    }
    &:hover {
      background: ${props => (props.isDark ? '#FFFFFF33' : 'transparent')} !important;
    }
  }

  ${props =>
    typeof props.mb === 'number' &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}

  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export default DatePicker;
