import { registerLicense } from '@syncfusion/ej2-base';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'theme-context';
import App from './App';
import { store } from './app/store';
import './assets/global.scss';
import { SYNCFUSION_KEY } from './configs/env-vars';
import reportWebVitals from './reportWebVitals';

registerLicense(SYNCFUSION_KEY);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
