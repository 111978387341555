import { DownloadOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { getUser } from 'app/services/selector-helpers';
import { removeUser } from 'app/slices/user';
import { useAppSelector } from 'app/store';
import { Col, Paragraph, Row, Switch } from 'components/atoms';
import { APP_VERSION } from 'configs/env-vars';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from 'theme-context';
import { showConfirm } from 'utils/helpers';
import GoDashboardInactive from '../../assets/images/go-dashboard-inactive.png';
import GoDashboard from '../../assets/images/go-dashboard.png';
import Settings from '../../assets/images/settings.png';

const DashboardHeader = ({
  toggleDropdown,
  role,
  dropdownVisible,
}: {
  role: string;
  dropdownVisible: boolean;
  toggleDropdown: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(getUser);
  // const isDark = useAppSelector(getTheme);
  const { colors, isDark, toggleTheme } = useTheme();
  // const [isDark, setIsDark] = useState(localStorage.getItem('bw_theme') === 'dark');

  useEffect(() => {
    const dateContainer = document.getElementById('date-container');
    const timeContainer = document.getElementById('time-container');
    const myInterval = setInterval(() => {
      if (dateContainer && timeContainer) {
        dateContainer.innerHTML = moment().format('ll');
        timeContainer.innerHTML = moment().format('dddd, h:mm A');
      }
    }, 5 * 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const handleLogout = () => {
    const customModalStyle = {
      backgroundColor: isDark ? colors.dark_blue : '#fff',
    };

    const contentStyle = {
      color: isDark ? '#fff' : '#000',
    };

    const okButtonStyle = {
      backgroundColor: isDark ? 'transparent' : '#fff',
      color: isDark ? '#fff' : colors.primary,
      border: isDark ? `1px solid #fff` : `1px solid ${colors.primary}`,
    };
    const cancelButtonStyle = {
      backgroundColor: colors.primary,
      color: '#fff',
      border: isDark ? '1px solid red ' : `1px solid ${colors.primary}`,
    };

    showConfirm(
      {
        content: <div style={contentStyle}>Are you sure you want to log out and exit?</div>,
        onCancel: () => Modal.destroyAll(),
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: {
          style: okButtonStyle,
          onClick: () => {
            dispatch(removeUser());
            sessionStorage.removeItem('siteFilters');
            navigate('/sign-in');
            Modal.destroyAll();
          },
        },
        okButtonProps: {
          style: cancelButtonStyle,
        },
        bodyStyle: customModalStyle,
      },
      { isDark }
    );
  };

  const handleThemeChange = (e: boolean) => {
    toggleTheme();
    toggleDropdown();
  };
  const DropDownWrapper = styled.div`
    position: absolute;
    background-color: ${!isDark ? '#fff' : colors.dark_blue};

    top: 40px;
    right: -13px;
    z-index: 9;
    width: 249px;
    box-shadow: 0px 0px 3px #a3a3a3;
  `;
  const DropDownHeaderWrapper = styled.div`
    position: absolute;
    top: -13px;
    right: -13px;
    height: 71px;
    background: ${isDark ? colors.dark_blue : colors.main_color};
    z-index: 10;
    border-radius: 0 0 20px 20px;

    width: 250px;
    box-shadow: 2px 0px 4px #a3a3a3;
  `;
  const DashboardImg = styled.img`
    width: 48px;
    margin-right: 8px;
    cursor: pointer;
  `;

  const SettingImg = styled.img`
    width: 48px;
    margin-left: 16px;
    cursor: pointer;
  `;

  return (
    <Row
      justify='space-between'
      align='middle'
      padding='10px 15px'
      background={colors.main_color}
      radius='0px 0px 20px 20px'
      shadow='-1px 1px 3px #a3a3a3'
    >
      <Col height='100%' align='start'>
        <DashboardImg
          src={location?.pathname !== '/' ? GoDashboard : GoDashboardInactive}
          alt='go to'
          onClick={() => navigate('/')}
        />
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={16} color='#fff'>
              {user?.name || '-'}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff'>
              {user?.userName || '-'}
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col height='100%' align='center'>
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={16} color='#fff' id='date-container'>
              {moment().format('ll')}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff' id='time-container'>
              {moment().format('LT')}
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col height='100%' align='end' style={{ position: 'relative' }}>
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={16} color='#fff' cursor='pointer' onClick={toggleDropdown}>
              <UserOutlined style={{ fontSize: '18px' }} /> Account{' '}
              <DownOutlined
                style={{ fontSize: '10px', marginLeft: '5px' }}
                rotate={dropdownVisible ? 180 : 0}
              />
            </Paragraph>
          </Col>

          {dropdownVisible && (
            <div onBlurCapture={() => toggleDropdown()}>
              <DropDownHeaderWrapper>
                <Row
                  align='middle'
                  justify={'space-between'}
                  style={{ marginBottom: '10px', cursor: 'pointer', padding: '25px 25px  0 20px ' }}
                  onClick={toggleDropdown}
                >
                  {' '}
                  <Paragraph fw={700} fz={16} color={'#fff'} style={{ cursor: 'pointer' }}>
                    <UserOutlined style={{ fontSize: '18px' }} /> Account{' '}
                  </Paragraph>
                  <DownOutlined
                    style={{
                      fontSize: '10px',
                      marginLeft: '5px',
                      color: '#fff',
                    }}
                    rotate={dropdownVisible ? 180 : 0}
                  />
                </Row>
              </DropDownHeaderWrapper>
              <DropDownWrapper>
                <div
                  style={{
                    marginBottom: '10px',
                    cursor: 'pointer',
                    padding: '40px 0 0 20px',
                    display: 'flex',
                  }}
                >
                  <Switch
                    onChange={handleThemeChange}
                    defaultChecked={isDark}
                    size='small'
                    border={`2px solid ${!isDark ? '#002885' : '#fff'}`}
                    padding='4px 3px 3px 0'
                    isheader={true}
                    back_color='#fff'
                    switcher_color={`${!isDark ? '#002885' : '#fff'}`}
                  />
                  <div>
                    <Paragraph
                      fw={400}
                      fz={15}
                      color={!isDark ? '#002885' : '#fff'}
                      onClick={() => handleThemeChange(!isDark)}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    >
                      {isDark ? 'Light Mode' : 'Dark Mode'}
                    </Paragraph>
                  </div>
                </div>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    padding: '0px 0 20px 20px',
                    alignItems: 'flex-end',
                  }}
                  onClick={handleLogout}
                >
                  <DownloadOutlined
                    rotate={90}
                    style={{
                      fontSize: '30px',
                      marginRight: '11px',
                      marginLeft: '-3px',
                      color: !isDark ? '#002885' : '#fff',
                    }}
                  />
                  <Paragraph
                    fw={400}
                    fz={15}
                    color={!isDark ? '#002885' : '#fff'}
                    style={{ marginLeft: '5px' }}
                  >
                    Log out
                  </Paragraph>
                </div>
              </DropDownWrapper>
            </div>
          )}
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#718096'>
              {APP_VERSION || 'v23.1211.0839'}
            </Paragraph>
          </Col>
        </Row>
        <SettingImg src={Settings} alt='go to' />
      </Col>
    </Row>
  );
};

export default DashboardHeader;
