import { store } from 'app/store'; // Import the Redux store directly
import { darkThemeColors, lightThemeColors } from './colorsProxy'; // Import themes separately

// Function to determine the current theme colors
const dynamicColors = () => {
  const state = store.getState(); // Access the current state directly
  const isDark = state.theme.isDark; // Access the isDark value from the Redux slice
  return isDark ? darkThemeColors : lightThemeColors;
};

// Proxy to dynamically access theme properties
export const colors = new Proxy(dynamicColors(), {
  get: (target, key) => {
    const currentTheme = dynamicColors(); // Recompute theme dynamically
    return currentTheme[key]; // Return the requested property
  },
});
