// src/slices/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDark: localStorage.getItem('bw_theme') === 'dark', // Initialize based on localStorage
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme(state) {
      state.isDark = !state.isDark;
      localStorage.setItem('bw_theme', state.isDark ? 'dark' : 'light'); // Update localStorage
    },
    setTheme(state, action) {
      state.isDark = action.payload;
      localStorage.setItem('bw_theme', action.payload ? 'dark' : 'light'); // Update localStorage
    },
  },
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export default themeSlice.reducer;
