import { Spin as spin, SpinProps } from 'antd';
import styled from 'styled-components';

interface IMainSpinProps extends SpinProps {
  mb?: number;
  margin?: string;
  border?: string;
  radius?: string;
  padding?: string;
  width?: string;
  height?: string;
  back_color?: string;
  switched_color?: string;
  isheader?: boolean;
  switcher_color?: string;
  isDark?: boolean;
}

const Spin = styled(spin)<IMainSpinProps>`
  .ant-spin-dot-item {
    background-color: ${props => (props.isDark ? '#fff' : 'blue')};
  }
`;

export default Spin;
